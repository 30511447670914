/*
 * ログイン
 */
const route = {
    name: "Login",
    path: "/login",
    view: "Login",
    component: () =>
        import ("../LoginView.vue"),
    meta: {
        title: "ログイン"
    }
}

export default route
