/*
 * 見積書作成
 */
const route = {
    name: "CreateQuotation",
    path: "/projectmaster/createquotation/:id",
    view: "CreateQuotation",
    component: () =>
        import ("../CreateQuotation.vue"),
    meta: {
        title: "見積原稿作成"
    }
}

export default route
