/*
 * アイテム情報
 */
const route = {
    name: "ItemMaster",
    path: "/itemmaster",
    view: "ItemMaster",
    component: () =>
        import ("../ItemMaster.vue"),
    meta: {
        title: "アイテム情報"
    }
}

export default route