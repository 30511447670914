export const mutations = {
    setToken(state, payload) {
        state.token = payload;
    },
    setLoggedIn(state, payload) {
        state.isLoggedIn = payload;
    },
    setLogInError(state, payload) {
        state.logInError = payload;
    },
    setUserProfile(state, payload) {
        state.userProfile = payload;
    },
    setDashboardMiniDrawer(state, payload) {
        state.dashboardMiniDrawer = payload;
    },
    setDashboardShowDrawer(state, payload) {
        state.dashboardShowDrawer = payload;
    },
    addNotification(state, payload) {
        state.notifications.push(payload);
    },
    removeNotification(state, payload) {
        state.notifications = state.notifications.filter((notification) => notification !== payload);
    },
};


export const commitSetDashboardMiniDrawer = mutations.setDashboardMiniDrawer;
export const commitSetDashboardShowDrawer = mutations.setDashboardShowDrawer;
export const commitSetLoggedIn = mutations.setLoggedIn;
export const commitSetLogInError = mutations.setLogInError;
export const commitSetToken = mutations.setToken;
export const commitSetUserProfile = mutations.setUserProfile;
export const commitAddNotification = mutations.addNotification;
export const commitRemoveNotification = mutations.removeNotification;
