<template>
  <v-app-bar color="accent" dense app dark>
    <v-btn icon @click.stop="updated">
      <v-icon v-show="!mini" class="white--text">mdi-menu-open</v-icon>
      <v-icon v-show="mini" class="white--text">mdi-menu</v-icon>
    </v-btn>
    <v-toolbar-title class="mr-12 align-center">
      <span>見積管理システム</span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu :nudge-width="100" offset-y fixed z-index="9999" close-on-content-click>
      <template v-slot:activator="{ on }">
        <v-btn color="secondary" small v-on="on">
          <v-icon>mdi-account-circle</v-icon>
          <span class="mx-2">{{ user_name }}</span>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar color="accent">
                <span class="white--text headline">◯</span>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title disabled>{{ user_name }}</v-list-item-title>
              <v-list-item-subtitle disabled>権限：{{ permission_name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list flat>
          <v-list-item-group>
            <v-list-item @click.prevent="open">
              <v-list-item-icon>
                <v-icon color="accent">mdi-tools</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>企業情報</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item>
              <v-list-item-icon>
                <v-icon color="accent">mdi-cogs</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>プロフィール</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon color="accent">mdi-account-arrow-right</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ログアウト</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
// import { dispatchLogOut } from '@/store/main/actions';
import { readUserProfile } from '@/store/main/getters';

export default {
  name: "GlobalHeader",
  computed: {
    mini: {
      get () {
        return this.$store.state.sidebar.mini
      },
      set (value) {
        this.$store.commit('setMini', value)
      }
    },
    user_name () {
      const user = readUserProfile(this.$store);
      return user.user_name;
    },
    permission_name () {
      const user = readUserProfile(this.$store);
      if (!user.permission) {
          return '';
      }
      return user.permission.name;
    },
  },
  methods: {
    updated () {
      const isMini = this.mini
      this.mini = !isMini
    },
    open () {
      this.$emit('open')
    },
    async logout () {
      // 消すべきかも
      // await dispatchLogOut(this.$store);
      window.location.href = "/signout";
    }
  }
}
</script>
