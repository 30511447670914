<template>
  <router-view />
</template>

<script>
export default {
  name: "APP",
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.card-head {
  height: 5px;
  background-color: #1E88E5;
}
.card-head-warning {
  height: 5px;
  background-color: #e51e25;
}
</style>
