/*
 * 部署情報
 */
const route = {
    name: "DepartmentMaster",
    path: "/departmentmaster",
    view: "DepartmentMaster",
    component: () =>
        import ("../DepartmentMaster.vue"),
    meta: {
        title: "部署情報"
    }
}

export default route