/*
 * 権限情報
 */
const route = {
    name: "PermissionMaster",
    path: "/permissionmaster",
    view: "PermissionMaster",
    component: () =>
        import ("../PermissionMaster.vue"),
    meta: {
        title: "権限情報"
    }
}

export default route