export default {
  state: {
    drawer: true,
    mini: true
  },
  mutations: {
    setDrawer (state, drawer) {
      state.drawer = drawer
    },
    setMini (state, mini) {
      state.mini = mini
    }
  },
  actions: {
    setDrawer ({ commit }, drawer) {
      commit('setDrawer', drawer)
    },
    setMini ({ commit }, mini) {
      commit('setMini', mini)
    }
  }
}