import { api } from '@/common/api';
import router from '@/router';
import {
    commitAddNotification,
    commitSetLogInError,
    commitSetLoggedIn,
    commitSetUserProfile
} from '@/store/main/mutations';

export const actions = {
    async actionLogIn(context, payload) {
        const state = context.state.MainState;
        try {
            const response = await api.logInGetToken(payload.username, payload.password);
            const token = response.data.access_token;
            // const refresh_token = response.data.refresh_token;
            // const refresh_token = response.data.refresh_token;
            // const refresh_token = null;

            console.log(response);
            // console.log('access_token', token);
            if (token) {

                console.log("actionLogIn", 'T-1');

                // saveLocalToken(token);

                console.log("actionLogIn", 'T-2');

                // saveLocalRfreshToken(refresh_token);

                console.log("actionLogIn", 'T-3');

                // commitSetToken(state, token);

                console.log("actionLogIn", 'T-4');

                commitSetLoggedIn(state, true);

                console.log("actionLogIn", 'T-5');

                commitSetLogInError(state, false);

                console.log("actionLogIn", 'T-6');

                await dispatchGetUserProfile(context);

                console.log("actionLogIn", 'T-7');

                await dispatchRouteLoggedIn(context);

                console.log("actionLogIn", 'T-8');

                // 何故かMainStateのnotificationsがnullになる・・・
                state.notifications = [];

                console.log("actionLogIn", 'T-9');

                commitAddNotification(state, { content: 'Logged in', color: 'success' });

                console.log("actionLogIn", 'T-10');

            } else {
                await dispatchLogOut(context);
            }
        } catch (err) {
            commitSetLogInError(state, true);
            await dispatchLogOut(context);
        }
    },
    async actionLogOut(context) {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
    },
    async actionGetUserProfile(context) {
        try {
            const state = context.state.MainState;
            const response = await api.getMe(state.token);
            console.log("actionGetUserProfile", response);
            console.log(response.data);
            if (response.data) {
                commitSetUserProfile(state, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    actionRouteLoggedIn() {
        if (router.currentRoute.path === '/' || router.currentRoute.path === '/login') {
            router.push('/dashboard');
        }
    },
    async actionRemoveLogIn(context) {
        console.log('actionRemoveLogIn');
        const state = context.state.MainState;
        // removeLocalToken();
        // removeLocalRfreshToken();
        // commitSetToken(state, '');
        commitSetLoggedIn(state, false);
    },
    actionRouteLogOut() {
        if (router.currentRoute.path !== '/') {
            router.push('/');
        }
    },
    async actionCheckApiError(context, error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log('api error', error.response.data);
            console.log('api error', error.response.status);
            console.log('api error', error.response.headers);

            var status = error.response.status;
            switch (status) {
                case 401:
                case 403:
                    await dispatchLogOut(context);
                    break;
                case 400:
                    alert(error.response.data.detail);
                    break;
                case 409:
                    context.dispatch('notisError', "入力した" + error.response.data.detail + "は既に登録されています。");
                    break;
                default:
                    console.log(`Sorry, we are out of ${status}.`);
                    await dispatchLogOut(context);
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log('error.config', error.config);
    },

}

export const dispatchCheckApiError = actions.actionCheckApiError;
export const dispatchLogIn = actions.actionLogIn;
export const dispatchLogOut = actions.actionLogOut;
export const dispatchGetUserProfile = actions.actionGetUserProfile;
export const dispatchRouteLoggedIn = actions.actionRouteLoggedIn;
export const dispatchRemoveLogIn = actions.actionRemoveLogIn;
export const dispatchRouteLogOut = actions.actionRouteLogOut;
