/*
 * テスト画面
 */
const route = {
  name: "TestPage",
  path: "/testpage",
  view: "TestPage",
  component: () =>
      import ("../TestPage.vue"),
  meta: {
      title: "TestPage"
  }
}

export default route;