/*
 * アイテム情報
 */
const route = {
    name: "StructureMaster",
    path: "/structuremaster",
    view: "StructureMaster",
    component: () =>
        import ("../StructureMaster.vue"),
    meta: {
        title: "構成名称情報"
    }
}

export default route
