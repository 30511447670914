<template>
  <v-footer color="accent" dark app>
    <div><h3>Ver. 1.0.0-beta.1</h3></div>
    <v-spacer></v-spacer>
    <div>&copy; {{ new Date().getFullYear() }} B.P.WORKS inc.</div>
  </v-footer>
</template>

<script>
export default {
  name: "GlobalFooter",
  data: () => ({
  }),
};
</script>