import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';

import env from "./module/env";
import { MainState } from './main/state';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState({
        paths: ['env', 'MainState']
    })], // ステートデータを永続化
    modules: {
        env,
        MainState
    },
    mutations: {
        initialReset(state) {
            const initialState = state;
            Object.keys(initialState).forEach(parent => {
                if (parent === 'env') { return; }
                const page = initialState[parent];
                Object.keys(page).forEach(key => {
                    page[key] = '';
                })
            })
        },
    }
});
