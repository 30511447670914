/*
 * 構成情報
 */
const route = {
    name: "ConfigurationInformation",
    path: "/configurationinformation",
    view: "ConfigurationInformation",
    component: () =>
        import ("../ConfigurationInformation.vue"),
    meta: {
        title: "構成情報"
    }
}

export default route
