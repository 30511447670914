import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    methods: {
        createPageTitle: function(to) {
            if (to.meta.title) {
                document.title = to.meta.title;
            }
        }
    },
    watch: {
        '$route' (to) {
            this.createPageTitle(to);
        }
    },
    components: { App },
    created: () => (document.documentElement.setAttribute('lang', 'ja')),
    render: h => h(App)
}).$mount('#app')
