/*
 * エラー画面
 */
const route = {
    name: "NotFound",
    path: "*",
    view: "NotFound",
    component: () =>
        import ("../NotFound.vue"),
    meta: {
        title: "NotFound"
    }
}

export default route;