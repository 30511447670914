export default {
    state: {
        companyList: [],
        companyName: '',
        tel: '',
        fax: '',
        mailAddress: '',
        postCode: '',
        location: '',
        companyUrl: '',
        remarks: '',
        logo: '',
        type: '',
        selectedCompany: '',
        createValid: false,
        infoDialogDrawer: false,
        storeDialogDrawer: false,
        updateDialogDrawer: false,
        updateValid: false,
        deleteDialogDrawer: false,
        readDialogDrawer: false,
        pageCount: 1,
        perPage: 10,
        page: 1
    },
}
