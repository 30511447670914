export const getters = {
    hasAdminAccess: (store) => {
        return (
            store.state.MainState.userProfile &&
            store.state.MainState.userProfile.is_superuser && store.state.MainState.userProfile.is_active);
    },
    loginError: (store) => store.state.MainState.logInError,
    userProfile: (store) => store.state.MainState.userProfile,
    // token: (store) => store.state.MainState.token,
    isLoggedIn: (store) => store.state.MainState.isLoggedIn,
};

export const readHasAdminAccess = getters.hasAdminAccess;
export const readIsLoggedIn = getters.isLoggedIn;
export const readLoginError = getters.loginError;
// export const readToken = getters.token;
export const readUserProfile = getters.userProfile;
