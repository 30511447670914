// import { IUserProfile } from '@/interfaces';

export const AppNotification = {
    content: null,
    color: null,
    showProgress: false
}

export const MainState = {
    token: null,
    isLoggedIn: null,
    logInError: null,
    userProfile: null,
    dashboardMiniDrawer: null,
    dashboardShowDrawer: null,
    notifications: []
}
