/*
 * 案件情報
 */
const route = {
    name: "ProjectMaster",
    path: "/projectmaster",
    view: "ProjectMaster",
    component: () =>
        import ("../ProjectMaster.vue"),
    meta: {
        title: "案件情報"
    }
}

export default route;
