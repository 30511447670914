<template>
  <v-app id="inspire" :style="{background: this.$vuetify.theme.themes.light.background}">
    <GlobalHeader @open="readDialogOpen"/>
    <GlobalSideBar/>
    <v-main>
      <v-container fluid>
        <CompanyMasterUpdateDialog/>
        <CompanyMasterReadDialog/>
        <div id="loading" :class="[isLoading ? 'visible' : '']">
          <v-row>
            <v-col class="text-center" cols="12">
              <v-progress-circular indeterminate color="green" size="100" width="7" />
            </v-col>
            <v-col class="text-center" cols="12">読み込み中...</v-col>
          </v-row>
        </div>
        <div id="saving" :class="[isSaving ? 'visible' : '']">
          <v-row>
            <v-col class="text-center" cols="12">
              <v-progress-circular indeterminate color="green" size="100" width="7" />
            </v-col>
            <v-col class="text-center" cols="12">保存中...</v-col>
          </v-row>
        </div>
        <v-row justify="center" dense>
          <v-col>
            <v-alert v-model="isVisible" :type="type" transition="fade-transition" dense dismissible>
              {{ message }}
            </v-alert>
          </v-col>
        </v-row>
        <transition name="component-fade" mode="out-in">
          <router-view/>
        </transition>
      </v-container>
    </v-main>
    <GlobalFooter/>
  </v-app>
</template>

<script>
import companyMaster from "@/views/companyMaster/store/state";
import GlobalHeader from '@/views/layout/modules/GlobalHeader';
import GlobalSideBar from '@/views/layout/modules/GlobalSideBar';
import GlobalFooter from '@/views/layout/modules/GlobalFooter';
import loading from "@/views/layout/store/loadingStore";
import saving from "@/views/layout/store/savingStore";
import notis from "@/views/layout/store/notisStore";
import sidebar from "@/views/layout/store/sideBarStore";
import { commitSetReadDialogDrawer, commitSetStoreName, commitSetSelectedCompany } from '@/views/companyMaster/store/mutations';
import { readUserProfile } from '@/store/main/getters';
import { dispatchGetLogo } from "@/views/companyMaster/store/actions";

export default {
  name: 'MainLayout',
  components: {
    GlobalHeader,
    GlobalSideBar,
    GlobalFooter,
    'CompanyMasterUpdateDialog': () => import('@/views/companyMaster/modules/CompanyMasterUpdateDialog.vue'),
    'CompanyMasterReadDialog': () => import('@/views/companyMaster/modules/CompanyMasterReadDialog.vue'),
  },
  beforeCreate() {
    // window.onkeydown = function(e) {
    //   if (e.keyCode == 9)
    //     return false;
    // }
    if (!this.$store.hasModule('loading')) {
      this.$store.registerModule('loading', loading)
    }
    if (!this.$store.hasModule('saving')) {
      this.$store.registerModule('saving', saving)
    }
    if (!this.$store.hasModule('notis')) {
      this.$store.registerModule('notis', notis)
    }
    if (!this.$store.hasModule('sidebar')) {
      this.$store.registerModule('sidebar', sidebar)
    }
    commitSetStoreName(this.$store, "companyMaster")
    if (!this.$store.hasModule('companyMaster')) {
      this.$store.registerModule('companyMaster', companyMaster)
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.state.loading.isLoading
      },
      set () {
        const bool = !this.$store.state.loading.isLoading
        this.$store.commit('setLoading', bool)
      }
    },
    isSaving: {
      get () {
        return this.$store.state.saving.isSaving
      },
      set () {
        const bool = !this.$store.state.saving.isSaving
        this.$store.commit('setSaving', bool)
      }
    },
    isVisible: {
      get () {
        return this.$store.state.notis.isVisible
      },
      set (value) {
        this.$store.commit('setIsVisible', value)
      }
    },
    type: {
      get () {
        return this.$store.state.notis.type
      },
      set (value) {
        this.$store.commit('setType', value)
      }
    },
    icon: {
      get () {
        return this.$store.state.notis.icon
      },
      set (value) {
        this.$store.commit('setIcon', value)
      }
    },
    message: {
      get () {
        return this.$store.state.notis.message
      },
      set (value) {
        this.$store.commit('setMessage', value)
      }
    }
  },
  methods: {
    async readDialogOpen() {
      const user = readUserProfile(this.$store);
      if(!user.company.is_delete) {
        await dispatchGetLogo(this.$store, user.company);
        commitSetSelectedCompany(this.$store, user.company);
        commitSetReadDialogDrawer(this.$store, true);
      }
    },
  }
}
</script>

<style lang="scss">
#loading {
  width: 100%;
  height: 100%;
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0,0,0,.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}
#loading.visible {
  opacity: 1;
  visibility: inherit;
}
#saving {
  width: 100%;
  height: 100%;
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0,0,0,.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}
#saving.visible {
  opacity: 1;
  visibility: inherit;
}
</style>
