/*
 * 構成情報
 */
const route = {
    name: "CategoryMasterDetail",
    path: "/categorymaster/detail/:category_id",
    view: "CategoryMasterDetail",
    component: () =>
        import ("../CategoryMasterDetail.vue"),
    meta: {
        title: "カテゴリ構成情報"
    }
}

export default route
