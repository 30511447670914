/*
 * ダッシュボード
 */
const route = {
    name: "Dashboard",
    path: "/dashboard",
    view: "Dashboard",
    component: () =>
        import ('../DashboardView.vue'),
    meta: {
        title: "ダッシュボード"
    }
}

export default route
