/*
 * 構成情報
 */
const route = {
    name: "CategoryMasterDetailItem",
    path: "/categorymaster/detail/:category_id/item/:structures_id",
    view: "CategoryMasterDetailItem",
    component: () =>
        import ("../CategoryMasterDetailItem.vue"),
    meta: {
        title: "カテゴリ構成情報"
    }
}

export default route
