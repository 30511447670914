export const mutations = {
    setStoreName(store, value) {
        store.state.storeName = value
    },
    setCompanies(store, payload) {
        store.state[store.state.storeName].companyList = payload;
    },
    setCompany(store, payload) {
        // 更新したID以外のものを取り出す
        const companies = store.state[store.state.storeName].companyList.filter((company) => company.id !== payload.id);
        if (payload.is_delete !== true) {
            // 削除でなければ、更新後のデータを追加
            companies.push(payload);
        }
        // storeのデータを更新
        store.state[store.state.storeName].companyList = companies;
    },
    setCompanyName(store, value) {
        store.state[store.state.storeName].companyName = value
    },
    setTel(store, value) {
        store.state[store.state.storeName].tel = value
    },
    setFax(store, value) {
        store.state[store.state.storeName].fax = value
    },
    setMailAddress(store, value) {
        store.state[store.state.storeName].mailAddress = value
    },
    setPostCode(store, value) {
        store.state[store.state.storeName].postCode = value
    },
    setLocation(store, value) {
        store.state[store.state.storeName].location = value
    },
    setCompanyUrl(store, value) {
        store.state[store.state.storeName].companyUrl = value
    },
    setRemarks(store, value) {
        store.state[store.state.storeName].remarks = value
    },
    setLogo(store, value) {
        store.state[store.state.storeName].logo = value
    },
    setType(store, value) {
        store.state[store.state.storeName].type = value
    },
    setCreateValid(store, value) {
        store.state[store.state.storeName].createValid = value
    },
    setSelectedCompany(store, value) {
        store.state[store.state.storeName].selectedCompany = Object.assign({}, value);
    },
    setInfoDialogDrawer(store, value) {
        store.state[store.state.storeName].infoDialogDrawer = value
    },
    setStoreDialogDrawer(store, value) {
        store.state[store.state.storeName].storeDialogDrawer = value
    },
    setUpdateDialogDrawer(store, value) {
        store.state[store.state.storeName].updateDialogDrawer = value
    },
    setUpdateValid(store, value) {
        store.state[store.state.storeName].updateValid = value
    },
    setDeleteDialogDrawer(store, value) {
        store.state[store.state.storeName].deleteDialogDrawer = value
    },
    setReadDialogDrawer(store, value) {
        store.state[store.state.storeName].readDialogDrawer = value
    },
    setPageCount(store, value) {
        store.state[store.state.storeName].pageCount = value;
    },
    setPerPage(store, value) {
        store.state[store.state.storeName].perPage = value;
    },
    setPage(store, value) {
        store.state[store.state.storeName].page = value;
    },

}

export const commitSetStoreName = mutations.setStoreName;
export const commitSetCompanies = mutations.setCompanies;
export const commitSetCompany = mutations.setCompany;
export const commitSetCompanyName = mutations.setCompanyName;
export const commitSetTel = mutations.setTel;
export const commitSetFax = mutations.setFax;
export const commitSetMailAddress = mutations.setMailAddress;
export const commitSetPostCode = mutations.setPostCode;
export const commitSetLocation = mutations.setLocation;
export const commitSetCompanyUrl = mutations.setCompanyUrl;
export const commitSetRemarks = mutations.setRemarks;
export const commitSetLogo = mutations.setLogo;
export const commitSetType = mutations.setType;
export const commitSetCreateValid = mutations.setCreateValid;
export const commitSetSelectedCompany = mutations.setSelectedCompany;
export const commitSetInfoDialogDrawer = mutations.setInfoDialogDrawer;
export const commitSetStoreDialogDrawer = mutations.setStoreDialogDrawer;
export const commitSetUpdateDialogDrawer = mutations.setUpdateDialogDrawer;
export const commitSetUpdateValid = mutations.setUpdateValid;
export const commitSetDeleteDialogDrawer = mutations.setDeleteDialogDrawer;
export const commitSetReadDialogDrawer = mutations.setReadDialogDrawer;
export const commitSetPageCount = mutations.setPageCount;
export const commitSetPerPage = mutations.setPerPage;
export const commitSetPage = mutations.setPage;
