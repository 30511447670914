/*
 * カテゴリ情報
 */
const route = {
    name: "CategoryMaster",
    path: "/categorymaster",
    view: "CategoryMaster",
    component: () =>
        import ("../CategoryMaster.vue"),
    meta: {
        title: "カテゴリ情報"
    }
}

export default route