/*
 * ユーザー情報
 */
const route = {
    name: "UserMaster",
    path: "/usermaster",
    view: "UserMaster",
    component: () =>
        import ("../UserMaster.vue"),
    meta: {
        title: "ユーザー情報"
    }
}

export default route