<template>
  <div v-if="!isDisabled">
    <template>
      <v-list-item :to="path">
        <v-tooltip right transition="scroll-x-transition" :disabled="!mini">
          <template v-slot:activator="{ on }">
            <v-list-item-icon v-on="on">
              <v-icon v-text="icon"/>
            </v-list-item-icon>
          </template>
          <span>{{ title }}</span>
        </v-tooltip>
        <v-list-item-title v-text="title"/>
      </v-list-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'GlobalSideBarLink',
  props: [
    'path', 'title', 'icon', 'isDisabled'
  ],
  computed: {
    mini () {
      return this.$store.state.sidebar.mini
    }
  }
}
</script>
<style scoped>
</style>
