/*
 * 企業情報
 */
const route = {
    name: "CompanyMaster",
    path: "/companymaster",
    view: "CompanyMaster",
    component: () =>
        import ("../CompanyMaster.vue"),
    meta: {
        title: "企業情報"
    }
}

export default route
