import { readUserProfile } from '@/store/main/getters';

function getDisabled(store, display, control){
    const userProfile = readUserProfile(store);
    const permission = userProfile.permission.config[display];
    return Reflect.get(permission, control);
}

export default {
    getDisabled
}
