/*
 * 原価費目情報
 */
const route = {
    name: "CostItemMaster",
    path: "/costitemmaster",
    view: "CostItemMaster",
    component: () =>
        import ('../CostItemMaster.vue'),
    meta: {
        title: "原価費目情報"
    }
}

export default route