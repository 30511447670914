/*
 * 原価表作成
 */
const route = {
    name: "CreateCostList",
    path: "/projectmaster/createcostlist/:id",
    view: "CreateCostList",
    component: () =>
        import ("../CreateCostList.vue"),
    meta: {
        title: "原価表作成"
    }
}

export default route
