/*
 * 取引先情報
 */
const route = {
    name: "SuppliersMaster",
    path: "/suppliersmaster",
    view: "SuppliersMaster",
    component: () =>
        import ("../SuppliersMaster.vue"),
    meta: {
        title: "取引先情報"
    }
}

export default route;
