/*
 * 案件情報詳細
 */
const route = {
    name: "ProjectMasterDetail",
    path: "/projectmaster/detail/:project_id",
    view: "ProjectMasterDetail",
    component: () =>
        import ("../ProjectMasterDetail.vue"),
    meta: {
        title: "案件情報詳細"
    }
}

export default route
