/*
 * 原価費目情報
 */
const route = {
    name: "ItemGroupMaster",
    path: "/itemgroupmaster",
    view: "ItemGroupMaster",
    component: () =>
        import ('../ItemGroupMaster.vue'),
    meta: {
        title: "アイテムグループ情報"
    }
}

export default route