export default {
  state: {
    isVisible: false,
    type: 'success',
    message: ''
  },
  mutations: {
    setIsVisible (state, isVisible) {
      state.isVisible = isVisible
    },
    setType (state, type) {
      state.type = type
    },
    setMessage (state, message) {
      state.message = message
    }
  },
  actions: {
    setIsVisible ({ commit }, isVisible) {
      commit('setIsVisible', isVisible)
    },
    setType ({ commit }, type) {
      commit('setType', type)
    },
    setMessage ({ commit }, message) {
      commit('setMessage', message)
    },
    notisSuccess ({ commit }, message) {
      commit('setType', 'success')
      commit('setMessage', message)
      commit('setIsVisible', true)
      setTimeout(() => {
        commit('setIsVisible', false)
      }, 5000)
    },
    notisWarning ({ commit }, message) {
      commit('setType', 'warning')
      commit('setMessage', message)
      commit('setIsVisible', true)
      setTimeout(() => {
        commit('setIsVisible', false)
      }, 5000)
    },
    notisError ({ commit }, message) {
      commit('setType', 'error')
      commit('setMessage', message)
      commit('setIsVisible', true)
      setTimeout(() => {
        commit('setIsVisible', false)
      }, 5000)
    }
  }
}